import prevent from '@/assets/prevent.jpeg';
export default {
  name: 'PreventionGuide',

  setup() {
    return {
      prevent
    };
  }

};