import request from '@/utils/request'
import { urlEncode } from '@/utils/urlEncode'

const api = {
  Logon: 'dragon/login',
  Logout: 'dragon/logout',
  SendRegPhoneCode: 'dragon/user/sendRegPhoneCode',
  GetUserInfo: 'dragon/user/getUserInfo',
  UpdateGender: 'dragon/user/updateGender',
  GetOccupationList: 'dragon/user/getOccupationList',
  UpdatePersonalInformation: 'dragon/user/updatePersonalInformation',
  GetList: 'dragon/friends/getList',
  GetLikeList: 'dragon/friends/getLikeList',
  AddLikeOrNotLike: 'dragon/friends/addLikeOrNotLike',
  GetUserInfoById: 'dragon/user/getUserInfoById',
  SavePhotoAlbums: 'dragon/user/savePhotoAlbums',
  UpdateAlbumVipFlag: 'dragon/user/updateAlbumVipFlag',
  SubmitJobMaterial: 'dragon/user/submitJobMaterial',
  GetMemberWechat: 'dragon/friends/getMemberWechat',
  ReportMember: 'dragon/user/reportMember',
  GetMyInviteInfo: 'dragon/account/getMyInviteInfo',
  UpdateDistance: 'dragon/user/updateDistance',
  VerifySuccess: 'dragon/user/verifySuccess',
  GetInviteInfo: 'web-api/user/get-invite-info',
  TopInvite: 'web-api/user/top-invite'

}

/**
 * @param parameter
 * @returns {*}
 */
export function login (data) {
  return request({
    url: api.Logon,
    method: 'post',
    data: urlEncode(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function sendRegPhoneCode (phone, ticket, randstr) {
  return request({
    url: api.SendRegPhoneCode,
    method: 'get',
    params: {
      phone,
      ticket,
      randstr
    }
  })
}

export function getUserInfo () {
  return request({
    url: api.GetUserInfo,
    method: 'get',
  })
}

export function updateGender (gender) {
  return request({
    url: api.UpdateGender,
    method: 'post',
    data: urlEncode({
      gender
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function getOccupationList () {
  return request({
    url: api.GetOccupationList,
    method: 'get',
  })
}

export function updatePersonalInformation (data) {
  return request({
    url: api.UpdatePersonalInformation,
    method: 'post',
    data
  })
}

export function getList (param) {
  return request({
    url: api.GetList,
    method: 'get',
    params: param
  })
}

export function getLikeList (param) {
  return request({
    url: api.GetLikeList,
    method: 'get',
    params: param
  })
}

export function addLikeOrNotLike (memberId, type, addOrCancel) {
  return request({
    url: api.AddLikeOrNotLike,
    method: 'get',
    params: {
      memberId, type, addOrCancel
    }
  })
}

export function getUserInfoById (memberId) {
  return request({
    url: api.GetUserInfoById,
    method: 'get',
    params: {
      memberId
    }
  })
}

export function savePhotoAlbums (imageId) {
  return request({
    url: api.SavePhotoAlbums,
    method: 'post',
    data: [
      imageId
    ],
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateAlbumVipFlag (enable) {
  return request({
    url: api.UpdateAlbumVipFlag,
    method: 'post',
    data: urlEncode({
      enable
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function submitJobMaterial (arr) {
  return request({
    url: api.SubmitJobMaterial,
    method: 'post',
    data: arr,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getMemberWechat (memberId) {
  return request({
    url: api.GetMemberWechat,
    method: 'get',
    params: {
      memberId
    },
  })
}

export function reportMember (data) {
  return request({
    url: api.ReportMember,
    method: 'post',
    data: urlEncode(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function logout () {
  return request({
    url: api.Logout,
    method: 'post'
  })
}

export function getMyInviteInfo () {
  return request({
    url: api.GetMyInviteInfo,
    method: 'get'
  })
}

export function updateDistance (data) {
  return request({
    url: api.UpdateDistance,
    method: 'post',
    data: urlEncode(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function verifySuccess (param) {
  return request({
    url: api.VerifySuccess,
    method: 'get',
    params: param
  })
}

export function topInvite () {
  return request({
    url: api.TopInvite,
    method: 'get'
  })
}

export function getInviteInfo () {
  return request({
    url: api.GetInviteInfo,
    method: 'get'
  })
}

