// @ is an alias to /src
import store from '@/store';
import { computed, onMounted, ref } from 'vue';
import iosicon from '@/assets/iosicon.png';
import step3 from '@/assets/step3.png';
import step4 from '@/assets/step4.png';
import step2 from '@/assets/step2.png';
import step1 from '@/assets/step1.png';
import step5 from '@/assets/step5.png';
import step6 from '@/assets/step6.png';
import step7 from '@/assets/step7.png';
import safari from '@/assets/safari.png';
import point from '@/assets/point.png';
import wechatjt from '@/assets/wechatjt.png';
export default {
  name: 'ApplePage',
  components: {},

  setup() {
    const isWechat = ref(false);
    const show = ref(false);
    const userAgent = navigator.userAgent.toLowerCase();
    const match = userAgent.match(/MicroMessenger/i);

    if (match !== null && match.toString() === 'micromessenger') {
      isWechat.value = true;
    }

    const isIos = ref(false);
    isIos.value = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    const user = computed(() => {
      return store.getters.getUserInfo;
    });
    onMounted(() => {});

    const downIos = () => {
      if (isWechat.value) {
        show.value = true;
      } else {
        window.location.href = 'itms-services://?action=download-manifest&url=https://www.mianjuyuehui.net/download/manifest.plist';
      }
    };

    const close = () => {
      show.value = false;
    };

    return {
      user,
      iosicon,
      downIos,
      step3,
      step2,
      step4,
      step1,
      step5,
      step6,
      step7,
      safari,
      point,
      wechatjt,
      close,
      isWechat,
      show,
      isIos
    };
  }

};