import punish from '@/assets/punish.png';
import { punishList } from '@/api';
import { onMounted, ref } from 'vue';
export default {
  name: 'PunishPage',
  components: {},

  setup() {
    const list = ref([]);
    onMounted(() => {
      punishList().then(res => {
        if (res.code === 0) {
          list.value = res.data;
        }
      });
    });

    const toDay = time => {
      const date = new Date(time);
      return date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2);
    };

    const toType = (type, count) => {
      if (type === 1) {
        if (count >= 24) {
          const days = Math.floor(count / 24);
          const hours = count % 24;
          let day = '禁言' + days + '天';

          if (hours > 0) {
            day += hours + '小时';
          }

          return day;
        } else {
          return '禁言' + count + '小时';
        }
      } else {
        return '永久封号';
      }
    };

    return {
      punish,
      list,
      toDay,
      toType
    };
  }

};