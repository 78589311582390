import { createStore } from 'vuex'
import { getInviteInfo, getUserInfo } from '@/api/user'
import { getThresholdFlagSwitch } from '@/api'

export default createStore({
  state: {
    userInfo: {},
    switch: 0,
    token: '',
    invInfo: {},
  },
  getters: {
    getUserInfo: state=> state.userInfo,
    getSwitch: state=> state.switch,
    invInfo: state => state.invInfo,
    getToken: state => state.token
  },
  mutations: {
    SET_TOKEN: (state, data) => {
      state.token = data
    },
    SET_INFO: (state, info) => {
      state.userInfo = info
    },
    SET_ONE: (state, data) => {
      state.userInfo[data.name] = data.value
    },
    SET_SWITCH: (state, data) => {
      state.switch = data
    },
    SET_INV: (state, info) => {
      state.invInfo = info
    }
  },
  actions: {
    // 登录成功
    LoginSuccess ({ commit }, data) {
      return new Promise((resolve) => {
        commit
        localStorage.setItem('ACCESS_TOKEN', data.token)
        localStorage.setItem('IM_APPID', data.tencentIMSDKAppId)
        localStorage.setItem('MEMBER_ID', data.memberId)
        localStorage.setItem('IM_SIG', data.tencentIMUserSig)
        // selfInfo().then((res) => {
        //   if (res.code === 0) {
        //     start()
        //     resolve()
        //   } else {
        //     commit('SET_TOKEN', '')
        //     commit('SET_INFO', {})
        //     localStorage.removeItem(ACCESS_TOKEN)
        resolve()
        //   }
        // }).catch(() => {
        //   reject()
        // })
      })
    },

    // 刷新用户信息
    RefreshInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          if (res.code === 0) {
            commit('SET_INFO', res.data)
            resolve()
          } else {
            commit('SET_INFO', {})
            localStorage.removeItem('ACCESS_TOKEN')
            localStorage.removeItem('IM_APPID')
            localStorage.removeItem('MEMBER_ID')
            localStorage.removeItem('IM_SIG')
            reject()
          }
        }).catch(() => {
          reject()
        })
      })
    },
    SwitchInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getThresholdFlagSwitch().then(res => {
          if (res.code === 0) {
            commit('SET_SWITCH', parseInt(res.data.thresholdFlagSwitch))
            resolve(parseInt(res.data.thresholdFlagSwitch))
          } else {
            reject()
          }
        })
      })
    },
    //
    // // 刷新token
    // RefreshToken ({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     refreshToken().then(res => {
    //       if (res.code === 0) {
    //         localStorage.setItem(ACCESS_TOKEN, res.data)
    //         commit('SET_TOKEN', res.data)
    //         resolve()
    //       } else {
    //         commit('SET_TOKEN', '')
    //         commit('SET_INFO', {})
    //         localStorage.removeItem(ACCESS_TOKEN)
    //         reject()
    //       }
    //     }).catch(() => {
    //       reject()
    //     })
    //   })
    // },

    // 登出
    Logout ({ commit }) {
      return new Promise((resolve) => {
        commit('SET_INFO', {})
        localStorage.removeItem('ACCESS_TOKEN')
        localStorage.removeItem('IM_APPID')
        localStorage.removeItem('MEMBER_ID')
        localStorage.removeItem('IM_SIG')
        // 直接注销成功
        resolve()
        // logout().then(res => {
        //   if (res.code === 0) {
        //     localStorage.removeItem('ACCESS_TOKEN')
        //     localStorage.removeItem('IM_APPID')
        //     localStorage.removeItem('MEMBER_ID')
        //     localStorage.removeItem('IM_SIG')
        //     // 直接注销成功
        //     resolve()
        //   }
        // })
      })
    },
    // 修改单个用户信息
    SaveOne ({ commit }, data) {
      return new Promise((resolve) => {
        commit('SET_ONE', data)
        resolve()
      })
    },
    SaveToken ({ commit }, token) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', token)
        resolve()
      })
    },
    RefreshInv ({ commit }) {
      return new Promise((resolve) => {
        getInviteInfo().then(res => {
          if (res.code === 0) {
            commit('SET_INV', res.data)
          }
        })
        resolve()
      })
    }
  },
  modules: {
  }
})
