import { useRoute } from 'vue-router';
import { loveStrategyInfo } from '@/api';
import { ref } from 'vue';
export default {
  name: 'LoveStrategy',

  setup() {
    const info = ref({});
    const route = useRoute();

    if (route.params.id !== undefined && route.params.id !== '') {
      loveStrategyInfo(route.params.id).then(res => {
        if (res.code === 0) {
          info.value = res.data;
        }
      });
    }

    return {
      info
    };
  }

};